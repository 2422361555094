<template>
  <header class="app-header">
    <div>
      <router-link to="/">Leif Marcus</router-link> &ndash;
      <router-link to="/about">About</router-link>
    </div>
  </header>
  <router-view />
</template>

<style lang="scss">
body,
html {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  font-family: 'Helvetica Neue', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  background: #f2f2f2;
  overflow: hidden;
}
</style>

<style lang="scss" scoped>
.app-header {
  font-size: 1rem;
  line-height: 1rem;
  letter-spacing: 0.1ex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  a {
    color: #000;
    text-decoration: none;
  }
}
</style>
