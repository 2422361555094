import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SliderSlide = _resolveComponent("SliderSlide")!

  return (_openBlock(), _createElementBlock("div", {
    class: "slider",
    onScroll: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateSlideInView && _ctx.updateSlideInView(...args))),
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slides, (slide, index) => {
      return (_openBlock(), _createBlock(_component_SliderSlide, {
        key: slide.id,
        slide: slide,
        slideIndex: index
      }, null, 8, ["slide", "slideIndex"]))
    }), 128))
  ], 32))
}