import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24357272"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "srcSet", "sizes", "loading", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.src)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: _normalizeClass(['image', `image--fit-${this.fit}`, `image--${this.position}`]),
          src: _ctx.src,
          srcSet: _ctx.srcSet,
          sizes: _ctx.sizes,
          style: _normalizeStyle({ objectPosition: _ctx.image.fitPosition }),
          loading: _ctx.initialViewport ? 'lazy' : 'auto',
          alt: _ctx.alt
        }, null, 14, _hoisted_1))
      : _createCommentVNode("", true)
  ]))
}