export const setWindowState = <T>(name: 'pages' | 'slides', value: T): void => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).__STATE__ = (window as any).__STATE__ ?? {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).__STATE__[name] = value;
};

export const getWindowState = <T>(name: 'pages' | 'slides'): T | undefined => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (window as any)?.__STATE__?.[name] as T;
};
