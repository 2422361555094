
import { Options, Vue } from 'vue-class-component';
import { SlideImage } from '../types/slide';

@Options({
  props: {
    image: { type: Object },
    position: { type: String },
    initialViewport: { type: Boolean },
  },
})
export default class SliderImage extends Vue {
  image!: SlideImage;
  position!: string;
  initialViewport!: boolean;

  src: SlideImage['src'] = '';
  fit: SlideImage['fit'] = 'contain';
  frame: SlideImage['frame'] = 'frame';
  srcSet = '';
  sizes = '100vw';
  ratio?: number;
  alt?: string;

  created(): void {
    this.alt = this.image?.alt || this.image?.title;
    this.ratio = this.image?.srcs?.small?.height / this.image?.srcs?.small?.width;
    this.src = this.getSrc(this.image);
    this.fit = this.getFit(this.image);
    this.frame = this.getFrame(this.image);
    this.srcSet = this.getSrcSet(this.image);
    this.sizes = this.getSizes();
  }

  private getSrc(image: SlideImage): SlideImage['src'] {
    return image?.srcs?.small.src;
  }
  private getFit(image: SlideImage): SlideImage['fit'] {
    return image?.fit || 'contain';
  }
  private getFrame(image: SlideImage): SlideImage['frame'] {
    return image?.frame || 'frame';
  }

  private getSrcSet({ srcs }: SlideImage): string {
    if (!srcs) return '';

    return [
      `${srcs.small?.src} ${srcs.small?.width}w`,
      `${srcs.medium?.src} ${srcs.medium?.width}w`,
      `${srcs.large?.src} ${srcs.large?.width}w`,
      `${srcs.extraLarge?.src} ${srcs.extraLarge?.width}w`,
      `${srcs.extraExtraLarge?.src} ${srcs.extraExtraLarge?.width}w`,
    ].join(',');
  }

  private getSizes(): string {
    if (!this.position?.includes('-of-2') || !this.ratio) return '';

    const maxSmall = this.frame === 'frame' ? '98' : '100';
    const minSmall = this.frame === 'frame' ? '49' : '50';

    return [
      `(orientation: portrait) ${this.ratio >= 1 && this.fit !== 'cover' ? minSmall : maxSmall}vw`,
      `${maxSmall}vw`,
    ].join(',');
  }
}
