
import { Options, Vue } from 'vue-class-component';
import { Slide } from '../types/slide';
import SliderImage from './SliderImage.vue';

@Options({
  components: { SliderImage },
  props: {
    slide: { type: Object, default: undefined },
    slideIndex: { type: Number, default: 0 },
  },
})
export default class SliderSlide extends Vue {
  slide!: Slide;
  slideIndex!: number;
}
