
import { Options, Vue } from 'vue-class-component';
import SliderSlide from './SliderSlide.vue';
import { Slide } from '../types/slide';

@Options({
  components: {
    SliderSlide,
  },
  props: {
    slides: { type: Array, default: [] },
  },
})
export default class Slider extends Vue {
  private timer?: number;
  slides!: Slide[];
  current = 0;

  created(): void {
    window.addEventListener('resize', this.updateSlideInView);
  }

  destroyed(): void {
    window.removeEventListener('resize', this.updateSlideInView);
  }

  onClick(event: MouseEvent): void {
    const pageTreshold = window.innerWidth / 2;
    const { clientX } = event;
    const nextIndex = clientX <= pageTreshold ? this.current - 1 : this.current + 1;
    this.setNextSlide(nextIndex);
  }

  updateSlideInView(): void {
    if (this.timer != null) clearTimeout(this.timer);
    this.timer = setTimeout(() => this.setSlideInView(), 200);
  }

  setSlideInView(): void {
    const elInView = document.elementFromPoint(window.innerWidth * 0.5, window.innerHeight * 0.5);
    const el = elInView?.closest('.slide');
    if (!el) return;

    const id = el.id;
    const index = this.slides.findIndex((slide) => slide.name === id);
    this.setNextSlide(index);
  }

  setNextSlide(add: number): void {
    this.current = (add + this.slides.length) % this.slides.length;
    const nextEl = document.querySelector(`.slide:nth-child(${this.current + 1})`);
    nextEl?.scrollIntoView({ behavior: 'smooth', inline: 'start' });
  }
}
