import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c8169df"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SliderImage = _resolveComponent("SliderImage")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['slide', `slide--${_ctx.slide.images.length}-images`]),
    id: _ctx.slide.name
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slide.images, (image, index) => {
      return (_openBlock(), _createBlock(_component_SliderImage, {
        class: _normalizeClass(['slide-image', `slide-image--has-${image.frame}`]),
        image: image,
        key: image.id,
        position: `${index + 1}-of-${_ctx.slide.images.length}`,
        initialViewport: _ctx.slideIndex !== 0
      }, null, 8, ["class", "image", "position", "initialViewport"]))
    }), 128))
  ], 10, _hoisted_1))
}